import React, { Component, Suspense } from 'react';

const Section = React.lazy(() => import('./section'))
const NavbarPage = React.lazy(() => import('../components/Navbar'))
const About  = React.lazy(() => import('../components/About'))
const FormRegister = React.lazy(() => import('./RegistrationPage'))
const Footer  = React.lazy(() => import('../components/Footer'))

class Index extends Component{
    constructor(props) {
        super(props);
        this.state = {
            navItems : [
                {id:1, idnm:'home', navheading: 'Home'},
                {id:2, idnm:'about', navheading: 'About'},
                {id:3, idnm:'register', navheading: 'Register'},
            ],
            pos : document.documentElement.scrollTop,
            imglight : true,
            navClass : ""
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup=document.documentElement.scrollTop;
        if(scrollup > this.state.pos)
        {
            this.setState({navClass : "darkheader", imglight : false});
        }
        else
        {
            this.setState({navClass : "", imglight : true});
        }
    }

    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">Loading...</div>
                </div>
            </div>
        );
    }

    render(){
        return(
            <>
                <Suspense fallback = {this.Loader()} >
                    <NavbarPage navItems={this.state.navItems} navclassName={this.state.navClass}/>

                    <Section/>

                    <About/>

                    <FormRegister/>

                    <Footer/>
                </Suspense>
            </>
        )
    }
}

export default Index