import React, { useState, useEffect } from 'react';
import '../styles/style.css';
import { useForm } from 'react-hook-form';
import URL from '../services/API_URL';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import logo from '../assets/images/logo.png'

const InputTokenPage = () => {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate()
    const [btn, setBtn] = useState({ text: 'Submit Kode', disableBtn: false });

    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);

    const onSubmit = (data) => {
        setBtn({ text: 'Submiting...', disableBtn: true })
        URL.API_URL.post ('check-voucher', data)
            .then((res) => {
                console.log(res)
                setBtn({ text: 'Submint Kode', disableBtn: false })
                // if (res.data.data === 'expired') {
                //     setError('token_test', { type: 'custom', message: res.data.message });
                // } else if (res.data.data === 'failed') {
                //     setError('token_test', { type: 'custom', message: res.data.message });
                // } else {
                //     navigate("/start-ielts-test", { state: res.data.data })
                // }
            })
            .catch((err) => console.log(err))
    }

    return (
        <>
            <div className="center-container">
                <div>
                    <img data-aos='fade-down' data-aos-delay='250' className='mb-3' src={logo} alt="logo" height="80" />
                    <h1 data-aos='fade-down' className='mb-5'>IELTS <span className='text-danger'>SIMULATION</span></h1>
                    <div data-aos='fade-up' className="box-token">
                        <h4>Submit Your Voucher Kode</h4>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3">
                                <input {...register("code_voucher")} type="password" className={`form-control token-input ${errors.code_voucher ? 'is-invalid' : ''}`} placeholder="Kode" />
                                {errors.code_voucher && <div className='invalid-feedback'>{errors.code_voucher.message}</div>}
                            </div>
                            <button disabled={btn.disableBtn} type='submit' className='btn-form btn-kuning'>{btn.text}</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InputTokenPage