import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import AOS from 'aos';
import 'aos/dist/aos.css';

import logo from '../../src/assets/images/logo.png'
import URL from '../services/API_URL';

const PaymentPage = () => {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();

    let { token } = useParams();
    const navigate = useNavigate()
    const [data, setData] = useState({});
    const [btn, setBtn] = useState({ text: 'Submit code', disableBtn: false });

    useEffect(() => {
        getDataRegis()
    }, [])

    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, [])

    const payment = () => {
        window.snap.pay(data.snap_token, {
            onSuccess: function (result) {

            },
            onPending: function (result) {
            },
            onError: function (result) {

            }
        });
    }

    const getDataRegis = () => {
        URL.API_URL.get ('payment/' + token)
            .then(res => setData(res.data))
            .catch(err => console.log(err))
    }

    const formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }

    const onSubmit = (data) => {
        setBtn({ text: 'Submiting...', disableBtn: true })
        URL.API_URL.post ('check-voucher', data)
            .then((res) => {
                setError('code_voucher', { type: 'custom', message: res.data.message });
                setBtn({ text: 'Submit code', disableBtn: false })
            })
            .catch((err) => console.log(err))
    }

    return (
        <section style={{ height: '100vh', display: 'flex', justifyContent: 'center' }} className='bg-lightblue'>
            <div className=''>
                <div className='text-center mt-3'>
                    <img data-aos='fade-down' data-delay-aos='250' className='mb-1' src={logo} alt="logo" height="60" />
                    <h1 data-aos='fade-down' className='mb-2'>IELTS <span className='text-danger'>SIMULATION</span></h1>
                </div>
                <div data-aos='fade-down' data-delay-aos='500' className="card-payment">
                    <button onClick={payment} className="proceed btn-kuning">Other Payment</button>
                    <img src="https://seeklogo.com/images/V/VISA-logo-62D5B26FE1-seeklogo.com.png" alt="img-payment" className="logo-card" />
                    <label className="label-payment">Nomor Rekening :</label>
                    <input readOnly type="text" className="input-payment cardnumber" placeholder="4350 44 1234" />
                    <label className="label-payment">Nama Rekening :</label>
                    <input readOnly className="input-payment name" placeholder="NARESY INDONESIA CV" />
                    <label className="toleft label-payment">Bank :</label>
                    <input readOnly className="input-payment toleft ccv" placeholder="BCA" />
                </div>
                <div data-aos='fade-up' data-delay-aos='250' className="receipt">
                    <div className="col">
                        <span>Nominal:</span>
                        <div className="cost">{formatRupiah(data.harga)}</div><br />
                        <span>Nama Member:</span>
                        <div className="cost">{data.nama_lengkap}</div>
                    </div>
                    <div className="col">
                        <h3 className="bought-items">How to Pay ?</h3>
                        <p className="bought-items description">You can make payment by transferring to the account number listed, you can also <strong>choose another payment method </strong> by clicking the <strong>Other payment</strong> button.</p>
                    </div>
                    <p className="comprobe">This information will be sended to your email</p>
                </div>
                <div style={{ height: '10px' }}></div>
                <div className='row justify-content-center mb-2'>
                    <div className='col-md-10'>
                        <span className='text-danger'>already have a token ?</span>
                        <button className='btn-form btn-red' onClick={() => navigate('/start-ielts-test')}>Input Token</button>
                    </div>
                </div>
                <div style={{ height: '10px' }}></div>
                <form onSubmit={handleSubmit(onSubmit)} className='form-inline mt-10'>
                    <div className='row justify-content-center'>
                        <span className='text-danger text-center'>have a voucher code ?</span>
                        <div className='col-md-5'>
                            <input {...register("code_voucher")} type="text" className={`form-control token-input ${errors.code_voucher ? 'is-invalid' : ''}`} placeholder="input code voucher here" />
                                {errors.code_voucher && <div className='invalid-feedback'>{errors.code_voucher.message}</div>}
                        </div>
                        <input {...register("token")} type='hidden' value={token} />
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                            <button  className='btn-form btn-kuning' disabled={btn.disableBtn} type='submit' >{btn.text}</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>

    )
}

export default PaymentPage