import React, { useEffect, useState } from 'react'
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

const ReadingQuestionPage = ({ parts, soal, inputDisabled, answerState }) => {
    const [sizes, setSizes] = useState([100, '8%', 'auto']);
    const [isMobile, setIsMobile] = useState(false)

    const handleChange = (e, number) => {
        const { value } = e.target
        let index = number - 1
        let _answer = [...answerState];
        _answer[index].user_answer = value
        _answer.push()

        if (value !== '') {
            document.getElementById(`span${number}`).classList.add("bold")
        } else {
            document.getElementById(`span${number}`).classList.remove("bold")
        }
    }

    const handleResize = () => {
        if (window.innerWidth < 769) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
    })

    return (
        <>

            {
                soal?.map((m, i) => {
                    return (
                        <div key={i} style={{ display: `${parts.activeObject == m.part_soal ? 'block' : 'none'}` }}>
                            <div style={{ height: '100vh' }}>
                                <SplitPane
                                    split={isMobile ? 'horizontal' : 'vertical'}
                                    sizes={sizes}
                                    onChange={setSizes}
                                >
                                    <Pane minSize={100}>
                                        <div className={`${isMobile ? 'pane-left-mobile' : 'pane-left'}`}>
                                            <div className={`inner-pane-top`}>
                                                <div dangerouslySetInnerHTML={{ __html: m.text_direction }} />
                                                <div className="img text-center">
                                                    <img src={m.image} style={{width:'100%'}} alt="img" />
                                                </div>
                                                <div dangerouslySetInnerHTML={{ __html: m.text }} />
                                            </div>
                                        </div>
                                    </Pane>
                                    <div style={{ overflow: 'auto', height: '100%' }}>
                                        <div className={`${isMobile ? 'inner-pane-mobile' : 'inner-pane'}`}>
                                            {
                                                m.sub_part.map((s, k) => {
                                                    return (
                                                        <div style={{ marginBottom: '40px' }}>
                                                            <h5 className='text-blue fs-20'>{s.sub_part}</h5>
                                                            <div dangerouslySetInnerHTML={{ __html: s.text_direction }} />
                                                            <div dangerouslySetInnerHTML={{ __html: s.text }} />
                                                            {
                                                                s.soal.map((d, k) => {
                                                                    return (
                                                                        <div style={{ marginBottom: '10px' }}>
                                                                            {d.soal == '' || d.soal == null ? '' : <td className='p-2 mb-2'>  <span dangerouslySetInnerHTML={{ __html: d.soal }} /></td>}
                                                                            {
                                                                                d.input_type === 'text' ?
                                                                                    (<input disabled={inputDisabled ? true : false} className='form-control answer-input mb-1' type="text" onChange={(e) => handleChange(e, d.number, k)} name={`input${d.number}`} id={`input${d.number}`} placeholder={d.number} />)
                                                                                    :
                                                                                    d.input_type === 'select' ?
                                                                                        (<select disabled={inputDisabled ? true : false} className='form-select answer-input' defaultValue={''} onChange={(e) => handleChange(e, d.number, k)} name={`input${d.number}`} id={`input${d.number}`}>
                                                                                            <option value="">Choose Answer</option>
                                                                                            <option value={d.opsi_jawaban.opsi_1}>{d.opsi_jawaban.opsi_1}</option>
                                                                                            <option value={d.opsi_jawaban.opsi_2}>{d.opsi_jawaban.opsi_2}</option>
                                                                                            <option value={d.opsi_jawaban.opsi_3}>{d.opsi_jawaban.opsi_3}</option>
                                                                                            {d.opsi_jawaban.opsi_4 != null ? (<option value={d.opsi_jawaban.opsi_4} >{d.opsi_jawaban.opsi_4}</option>) : ''}
                                                                                            {d.opsi_jawaban.opsi_5 != null ? (<option value={d.opsi_jawaban.opsi_5} >{d.opsi_jawaban.opsi_5}</option>) : ''}
                                                                                            {d.opsi_jawaban.opsi_6 != null ? (<option value={d.opsi_jawaban.opsi_6} >{d.opsi_jawaban.opsi_6}</option>) : ''}
                                                                                            {d.opsi_jawaban.opsi_7 != null ? (<option value={d.opsi_jawaban.opsi_7} >{d.opsi_jawaban.opsi_7}</option>) : ''}
                                                                                            {d.opsi_jawaban.opsi_8 != null ? (<option value={d.opsi_jawaban.opsi_8} >{d.opsi_jawaban.opsi_8}</option>) : ''}
                                                                                        </select>)
                                                                                        :
                                                                                        d.input_type === 'radio' ?
                                                                                            <>
                                                                                                <div className='form-check'>
                                                                                                    <input className='form-check-input' type="radio" value={d.opsi_jawaban.opsi_1} onChange={(e) => handleChange(e, d.number, k)} name={`input${d.number}`} id={`input${d.number}`} />A. {d.opsi_jawaban.opsi_1}
                                                                                                </div>
                                                                                                <div className='form-check'>
                                                                                                    <input className='form-check-input' type="radio" value={d.opsi_jawaban.opsi_2} onChange={(e) => handleChange(e, d.number, k)} name={`input${d.number}`} id={`input${d.number}`} />B. {d.opsi_jawaban.opsi_2}
                                                                                                </div>
                                                                                                <div className='form-check'>
                                                                                                    <input className='form-check-input' type="radio" value={d.opsi_jawaban.opsi_3} onChange={(e) => handleChange(e, d.number, k)} name={`input${d.number}`} id={`input${d.number}`} />C. {d.opsi_jawaban.opsi_3}
                                                                                                </div>
                                                                                                <div className='form-check'>
                                                                                                    <input className='form-check-input' type="radio" value={d.opsi_jawaban.opsi_4} onChange={(e) => handleChange(e, d.number, k)} name={`input${d.number}`} id={`input${d.number}`} />D. {d.opsi_jawaban.opsi_4}
                                                                                                </div>
                                                                                            </>

                                                                                            :
                                                                                            ''
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                </SplitPane>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default ReadingQuestionPage
