import React from 'react'
import '../../styles/style.css'
import 'split-pane-react/esm/themes/default.css';

const ListeningQuestionPage = ({soal, parts, answerState, inputDisabled}) => {
    
    const handleChange = (e, number) => {
        const { value } = e.target
        let index = number - 1
        let _answer = [...answerState];
        _answer[index].user_answer = value
        _answer.push()
        
        if (value !== '') {
            document.getElementById(`span${number}`).classList.add("bold")
        } else {
            document.getElementById(`span${number}`).classList.remove("bold")
        }
    }

  return (
    <>
    <audio autoPlay id="audio" > 
        { soal.length > 0 ? <source src={soal[0].image} type="audio/mp3" /> : ''}
    </audio>
    {
        soal?.map((m, i) => {
            return (
                <div key={i} style={{display: `${parts.activeObject == m.part_soal ? 'block' : 'none'}`, marginBottom: '50px'}}>
                     <div>
                        <h3 className='text-blue fs-24 fw-bold' >{m.part}</h3>
                        <div dangerouslySetInnerHTML={{__html: m.text_direction}} />
                        {m.image != null ? (<img src={m.image}  alt="" />) : ''}
                        {m.text != null ? (<div dangerouslySetInnerHTML={{__html: m.text}} />) : ''}

                    <tbody>
                {
                    m.soal?.map((s, k) => {
                        return(
                            <>
                                <tr>
                                {s.soal == '' || s.soal == null ?  '' :<td className='p-2'><strong>-</strong></td> }  
                                <td>{<div style={{fontSize:'22px'}} dangerouslySetInnerHTML={{__html: s.soal}} />}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                    {
                                        s.input_type === 'text' ? 
                                        (<input disabled={inputDisabled ? true : false} className='form-control answer-input mb-3' type="text" onChange={(e) => handleChange(e, s.number, k)} name={`input${s.number}`} id={`input${s.number}`} placeholder={s.number} />) :
                                        s.input_type === 'select' ? 
                                        (<select className='form-select answer-input mb-2' defaultValue={''} onChange={(e) => handleChange(e, s.number, k)} name={`input${s.number}`} id={`input${s.number}`}>
                                            <option value="">Choose Answer</option>
                                            <option value={s.opsi_jawaban.opsi_1}>{s.opsi_jawaban.opsi_1}</option>
                                            <option value={s.opsi_jawaban.opsi_2}>{s.opsi_jawaban.opsi_2}</option>
                                            <option value={s.opsi_jawaban.opsi_3}>{s.opsi_jawaban.opsi_3}</option>
                                            {s.opsi_jawaban.opsi_4 != null ? (<option value={s.opsi_jawaban.opsi_4} >{s.opsi_jawaban.opsi_4}</option>) : ''}
                                            {s.opsi_jawaban.opsi_5 != null ? (<option value={s.opsi_jawaban.opsi_5} >{s.opsi_jawaban.opsi_5}</option>) : ''}
                                            {s.opsi_jawaban.opsi_6 != null ? (<option value={s.opsi_jawaban.opsi_6} >{s.opsi_jawaban.opsi_6}</option>) : ''}
                                            {s.opsi_jawaban.opsi_7 != null ? (<option value={s.opsi_jawaban.opsi_7} >{s.opsi_jawaban.opsi_7}</option>) : ''}
                                        </select>) : ''
                                    }
                                    </td>
                                </tr>
                                             
                            </>                            
                        )
                    })
                }
                </tbody>
                     </div>
                </div>
            )
        })
    }
    </>
  )
}

export default ListeningQuestionPage