import React, { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { PopupButton, useCalendlyEventListener } from "react-calendly"
import AOS from 'aos';
import 'aos/dist/aos.css';
import logo from '../../assets/images/logo.png'

const IELTStestPageSpeaking = () => {
  const navigate = useNavigate()
  const [linkEmbed, setLink] = useState({ display: 'none' })

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      navigate('/thank-you', { replace: true })
    },
  })

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, [])

  const handleLink = (e) => {
    setLink({ display: '' })
  }

  return (
    <>
      <header className="bg-blue">
        <h2 className="text-white">IELTS Speaking</h2>
      </header>
      <div className="center-container">
        <div className="row">
          <div className="col-md-12">
            <div className='text-center my-4'>
              <img data-aos='fade-down' data-delay-aos='250' className='mb-3' src={logo} alt="logo" height="80" />
              <h1 data-aos='fade-down' className='mb-2'>IELTS <span className='text-danger'>SIMULATION</span></h1>
            </div>
            <p>The Speaking Test is carried out by holding an online meeting with our team, click the set schedule button to set the schedule.</p>
            <button className="btn-form btn-kuning mb-2" onClick={handleLink}>Set Schedule</button>
            <PopupButton styles={{ display: linkEmbed.display, width: '180px' }} className='btn-step4' rootElement={document.getElementById("root")} text="Click here to schedule!" url={'https://calendly.com/teacher-niecindonesia/nicespeaking'} />
          </div>
        </div>
      </div>
    </>
  )
}

export default IELTStestPageSpeaking