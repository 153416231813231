import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import URL from '../../services/API_URL';
import Timer from '../Timer';
import ListeningQuestionPage from './ListeningQuestionPage';
import Swal from 'sweetalert2'

const IELTStestPageListening = ({ nextPage }) => {
    const navigate = useNavigate()
    const [soalListening, setSoalListening] = useState([]);
    const location = useLocation();
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [popup, setPopup] = useState(true)
    const [startTime, setStartTime] = useState(false)
    const [page, setPage] = useState({
        activeObject: 1,
        activeFocus: null,
        part: [
            {
                id: 1,
                page: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
            },
            {
                id: 2,
                page: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
            },
            {
                id: 3,
                page: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
            },
            {
                id: 4,
                page: [31, 32, 33, 34, 35, 36, 37, 38, 39, 40]
            }]
    });
    const [answerState, setAnswerState] = useState([])

    useEffect(() => {
        if (location.state == null) {
            navigate('/start-ielts-test')
        }
        else if (location.state.is_active == 1) {
            if (popup) {
                Swal.fire({
                    title: "Are you ready for Listening Section?",
                    confirmButtonText: "Start",
                    allowOutsideClick: false,
                    icon: "question",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setPopup(false)
                        getSoal()
                        setStartTime(true)
                        document.getElementById('audio').play();
                    }
                });
            }
        }
    }, [])

    const nextPart = () => {
        if (page.activeObject === 4) return
        let increment = page.activeObject + 1;
        setPage(prev => ({ ...prev, activeObject: increment }))
    }

    const prevPart = () => {
        if (page.activeObject === 1) return
        let increment = page.activeObject - 1;
        setPage(prev => ({ ...prev, activeObject: increment }))
    }

    const submit = () => {
        setBtnDisabled(true)
        for (let i = 0; i < answerState.length; i++) {
            if (answerState[i].correct_answer.toUpperCase() === answerState[i].user_answer.toUpperCase()) {
                answerState[i].is_correct = true
                answerState.push()
            } else {
                answerState[i].is_correct = false
                answerState.push()
            }
        }
        let jawaban = answerState.filter((x) => x.is_correct == true)
        let data = {
            total_correct: jawaban.length,
            type: 'listening',
            answer: answerState
        }
        URL.API_URL.post('store-answer', data)
            .then((res) => {
                Swal.fire({
                    icon: "success",
                    title: "Your Listening Answers Saved",
                    showConfirmButton: false,
                    timer: 2500,
                    willClose: () => {
                        setBtnDisabled(false)
                        nextPage(2)
                    }
                });
            })
            .catch((err) => {
                setBtnDisabled(false)
            })
    }

    const toggleActivePallete = (index) => {
        setPage(prev => ({ ...prev, activeObject: page.part[index].id }))
    }

    const toggleActivePalleteClass = (index) => {
        if (page.activeObject == page.part[index].id) {
            return 'inactive is-mobile'
        } else {
            return 'active'
        }
    }

    const toggleFocus = (no) => {
        setPage(prev => ({ ...prev, activeFocus: no }))
        document.getElementById(`input${no}`).focus()
    }

    const getSoal = () => {
        URL.API_URL.get( 'soal-listening')
            .then((res) => {
                setSoalListening(res.data)
                // -----------------------
                let data = res.data
                let answer = []
                for (let i = 0; i < data.length; i++) {
                    let soal = data[i].soal
                    for (let j = 0; j < soal.length; j++) {
                        answer.push({ id_user: location.state?.id, id_soal: soal[j].id, correct_answer: soal[j].jawaban, user_answer: '', is_correct: false })
                    }
                }
                setAnswerState(answer)
            })
            .catch((err) => console.log(err))
    }

    return (
        <>
            <header className="bg-blue">
                <h2 className='text-white'>Listening Section</h2>
                <Timer startTime={startTime} timer={2400} category={'listening'} setInputDisabled={setInputDisabled} />
                <div className="nav-control">
                    <button disabled={btnDisabled ? true : false} className='btn btn-success' onClick={submit}>Submit</button>
                </div>
            </header>
            <div className="ielts-content">
                <ListeningQuestionPage parts={page} soal={soalListening} answerState={answerState} inputDisabled={inputDisabled} />
            </div>


            <div className="float-nav">
                <div><i onClick={prevPart} className={`fa-solid fa-circle-chevron-left ${page.activeObject === 1 ? 'disabled' : ''}`}></i></div>
                <div><i onClick={nextPart} className={`fa-solid fa-circle-chevron-right ${page.activeObject === 4 ? 'disabled' : ''}`}></i></div>
            </div>

            <footer>
                {
                    page.part.map((p, i) => {
                        let no = i + 1
                        return (
                            <div key={i} className={`question-palette ${page.activeObject !== no ? 'none' : ''}`} onClick={() => toggleActivePallete(i)}>
                                <div className='p-1'>Part {no}</div>
                                <div className={`question-palette__number ${toggleActivePalleteClass(i, no)}`}>
                                    {
                                        p.page.map((d, index) => {
                                            return (<span
                                                // className={`p ${d == page.activeFocus ? 'active' : ''}`}
                                                id={`span${d}`}
                                                key={index}
                                                onClick={() => toggleFocus(d)} >{d}</span>)
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </footer>
        </>
    )
}

export default IELTStestPageListening