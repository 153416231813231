import React, { useEffect, useState } from 'react'
import URL from '../../services/API_URL'
import { useLocation, useNavigate } from 'react-router-dom'
import ReadingQuestionPage from './ReadingQuestionPage';
import Timer from '../Timer';
import Swal from 'sweetalert2'

const IELTStestPageReading = ({nextPage}) => {
    const [soalReading, setSoalReading] = useState([])
    const location = useLocation();
    const navigate = useNavigate();
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [startTime, setStartTime] = useState(false)
    const [popup, setPopup] = useState(true)
    const [answerState, setAnswerState] = useState([])
    const [page, setPage] = useState({
      activeObject: 1,
      activeFocus: null,
      part:[
          { 
              id: 1,
              page: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
          },
          {
              id: 2,
              page: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]
          },
          {
              id: 3,
              page: [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40]
          }]
      });
    
    useEffect(() => {
      if (location.state == null) {
        navigate('/start-ielts-test')
      } else {
        if (popup) {
          Swal.fire({
              title: "Are you ready for Reading Section?",
              confirmButtonText: "Start",
              allowOutsideClick: false,
              icon: "question",
          }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                  setPopup(false)
                  getSoal()
                  setStartTime(true)
              } 
          });
        }       
      }
    }, [])
  
    const toggleActivePallete = (index) => {
      setPage(prev => ({...prev, activeObject: page.part[index].id}))
    }

  const toggleActivePalleteClass = (index) => {
    if (page.activeObject == page.part[index].id) {
        return 'inactive'
    } else {
        return 'active'
    }
  }

  const toggleFocus = (no) => {
    setPage(prev => ({...prev, activeFocus: no}))
    document.getElementById(`input${no}`).focus()
  }

  const submit = () => {
    for (let i = 0; i < answerState.length; i++) {
        if (answerState[i].correct_answer.toUpperCase() === answerState[i].user_answer.toUpperCase()) {
          answerState[i].is_correct = true
          answerState.push()  
        } else {
          answerState[i].is_correct = false
          answerState.push() 
        }
    }
    let jawaban = answerState.filter((x) => x.is_correct == true)
    let data = {
        total_correct: jawaban.length,
        type: 'reading',
        answer: answerState
    }

    URL.API_URL.post ('store-answer', data)
    .then((res) => {
        Swal.fire({
            icon: "success",
            title: "Your Reading Answers Saved",
            showConfirmButton: false,
            timer: 2500,
            willClose: () => {
                nextPage(3)
            }
        });
    })
    .catch((err) => {
        setBtnDisabled(false)
    })
}

  const getSoal = () => {
      URL.API_URL.get ('soal-reading')
      .then((res) => {
          setSoalReading(res.data)
          // -----------------------
          let data = res.data
          let answer = []
          for (let i = 0; i < data.length; i++) {
            let sub_part = data[i].sub_part
            for (let j = 0; j < sub_part.length; j++) {
              let soal = sub_part[j].soal
              for (let k = 0; k < soal.length; k++) {
                answer.push({id_user: location.state?.id, id_soal: soal[k].id, correct_answer: soal[k].jawaban, user_answer: '', is_correct: false})
              }
            }
          }
          
          setAnswerState(answer)
      })
      .catch((err) => console.log(err))
  }
  return (
    <>
    <header className="bg-blue">
        <h2 className='text-white'>Reading Section</h2>
        <Timer timer={3600} category={'reading'} startTime={startTime} setInputDisabled={setInputDisabled}/>
        <div className="nav-control">
            <button onClick={submit} className='btn btn-success'>Submit</button>
        </div>
    </header>
    
    <ReadingQuestionPage parts={page} answerState={answerState} soal={soalReading} inputDisabled={inputDisabled} />

    <footer>
      {
      page.part.map((p, i) => {
          let no = i+1
          return (
          <div key={i} className={`question-palette ${page.activeObject !== no ? 'none' : ''}`} onClick={() => toggleActivePallete(i)}>
              <div className='p-1'>Part {no}</div>
              <div className={`question-palette__number ${toggleActivePalleteClass(i, no)}`}>
                  {
                      p.page.map((d, index) => {
                          return(<span 
                            // className={`p ${d == page.activeFocus ? 'active' : ''}`}
                            id={`span${d}`}
                            key={index}
                            onClick={() => toggleFocus(d)} >{d}</span>)
                      })
                  }
              </div>
          </div>
          )
      })
      }
    </footer>
    </>
  )
}

export default IELTStestPageReading